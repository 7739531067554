
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React, { ReactNode } from 'react'
import CamerasList from 'components/camera/list/components/CamerasList'
import { CamerasListLayout } from 'components/Layouts/CamerasListLayout/CamerasListLayout'
import { PairingContextProvider } from 'context/PairingContext'
export default function Home() {
  return <CamerasList></CamerasList>
}

Home.getLayout = function getLayout(children: ReactNode) {
  return (
    <CamerasListLayout>
      <PairingContextProvider>{children}</PairingContextProvider>
    </CamerasListLayout>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  